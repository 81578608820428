<template>
  <div>
    <div v-if="edit" class="edit-div">
      <label for="name">{{ $t("label.name") }}</label>
      <input type="text" id="name" v-model="name" required />
      <br />
      <label for="surname">{{ $t("label.surname") }}</label>
      <input type="text" id="surname" v-model="surname" required />
      <br />
      <label for="email">{{ $t("label.email") }}</label>
      <span>{{ email }}</span>
      <router-link to="/changeemail"> {{ $t("label.change") }} </router-link>
      <br />
      <label for="password">{{ $t("password.password") }}</label>
      <router-link to="/changepassword"> {{ $t("label.change") }} </router-link>
      <br />
      <button
        id="confirmation-button"
        @click="submitEdit"
        :disabled="checkForm"
        class="save-button"
      >
        {{ $t("buttons.save") }}
      </button>
      <button id="back-button" @click="getBack" class="back-button">
        {{ $t("buttons.back") }}
      </button>
    </div>
    <div v-if="!edit" class="display">
      <h1>
        {{ capitalizeFirstLetter(name) + " " + capitalizeFirstLetter(surname) }}
      </h1>
      <button
        class="edit-button"
        @click="toogleEdit"
        :style="{
          backgroundColor: $store.getters.color.color1,
          borderColor: $store.getters.color.color1,
          color: $store.getters.color.fontColor,
        }"
        v-if="this.$store.getters.userId == this.$route.params.id"
      >
        {{ $t("buttons.edit") }}
      </button>

      <p>{{ email }}</p>
      <router-link
        :to="buildAgencyLink(tourAgency._id)"
        :style="{ color: $store.getters.color.color3 }"
        v-if="Object.keys(tourAgency).length > 0"
        >{{ capitalizeFirstLetter(tourAgency.name) }}</router-link
      >
    </div>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "TourAgencyEmployeeDisplay",
  data() {
    return {
      name: "",
      surname: "",
      email: "",
      tourAgency: {},
      password: "",
      passwordConfirmation: "",
      edit: false,
      emailConfirmation: "",
    };
  },
  async created() {
    this.getInformation();
  },
  computed: {
    checkForm: function () {
      if (!this.name) {
        return true;
      }
      if (!this.surname) {
        return true;
      }
      if (!this.email) {
        return true;
      }
      if (this.password.length < 8 && this.password.length > 0) {
        return true;
      }
      if (this.password != this.passwordConfirmation) {
        return true;
      }
      return false;
    },
    emailCheck: function () {
      if (this.email != this.emailConfirmation) {
        return this.$t("error.emailCheck");
      }
      return "";
    },
    passwordCheck: function () {
      if (this.password.length < 8 && this.password.length > 0) {
        return this.$t("password.min8caracters");
      }
      if (this.password != this.passwordConfirmation) {
        return this.$t("password.confirmationMustMatch");
      }
      return "";
    },
    passwordCheckStyle: function () {
      if (this.password.length < 8 && this.password.length > 0) {
        return "errorField";
      }
      if (this.password != this.passwordConfirmation) {
        return "errorField";
      }
      return "normalField";
    },
  },
  methods: {
    capitalizeFirstLetter: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getInformation: async function () {
      try {
        let information = await HandleApi.getEmployeeInformation(
          this.$route.params.id
        );
        if (
          information.employee.firstConnexion == true &&
          this.$store.getters.userId == this.$route.params.id
        ) {
          this.$router.push("/changepassword");
        }
        this.name = information.employee.name
          .replace(/&amp;/gi, "&")
          .replace(/&#x27;/gi, "'");
        this.surname = this.capitalizeFirstLetter(information.employee.surname);
        this.email = information.employee.email;
        this.tourAgency = information.employee.tourAgency;
        this.tourAgency.name = this.tourAgency.name
          .replace(/&amp;/gi, "&")
          .replace(/&#x27;/gi, "'");
      } catch (err) {
        this.$store.dispatch("push_error_message", err);
      }
    },
    getBack: function () {
      this.edit = false;
      this.getInformation();
    },
    buildAgencyLink(agencyId) {
      return "/agency/" + agencyId;
    },
    toogleEdit: function () {
      this.edit = true;
    },
    submitEdit: async function () {
      try {
        let newData = {
          name: this.name,
          surname: this.surname,
          email: this.email,
          tourAgency: this.tourAgency._id,
        };
        if (this.password) {
          newData["password"] = this.password;
        }
        let res = await HandleApi.putEmployee(this.$route.params.id, newData);
        if (res.status == 200) {
          this.$store.dispatch(
            "push_notification_message",
            this.$t("notification.updated", {
              object: this.$t("notification.profile"),
            })
          );
          this.$router.go("/employee/" + this.$route.params.id);
        }
      } catch (err) {
        this.$store.dispatch("push_error_message", err);
      }
    },
  },
};
</script>

<style scoped>
.edit-div {
  text-align: center;
  margin-top: 20px;
  line-height: 40px;
}

.edit-div label {
  margin-right: 10px;
  margin-left: 10px;
}

.error-message {
  border: 2px solid red;
  color: red;
  background-color: #ecb2b2;
  max-width: 90%;
  width: 500px;
  margin: 0 auto;
  border-radius: 10px;
}

.app-container {
  display: inline-block;
}

.remove-app-button {
  margin: 10px;
  color: white;
  height: 21px;
  width: 21px;
  text-align: center;
  background-color: #ff3a3a;
  border-radius: 30px;
  border: none;
  padding: 0;
}

.add-app-button {
  background-color: rgb(55, 187, 248);
  color: white;
  border: none;
  border-radius: 3px;
  height: 20px;
  font-weight: bold;
  width: 30px;
}

.save-button {
  background-color: rgb(9, 201, 111);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.save-button:disabled {
  background-color: rgb(139, 196, 169);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}
.back-button {
  background-color: rgb(255, 72, 72);
  color: white;
  border: none;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.errorField {
  -moz-box-shadow: 0 0 3px red;
  -webkit-box-shadow: 0 0 3px red;
  box-shadow: 0 0 3px red;
}

.display {
  text-align: center;
  margin: 0 auto;
}

.display hr {
  width: 30%;
}

.display h1 {
  display: inline-block;
  margin-right: 10px;
}

.edit-button {
  box-shadow: 2px 2px 2px lightgrey;
  color: white;
  border: 2px solid #00909f;
  font-size: 17px;
  background-color: #00909f;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 7px;
  margin: auto 0;
}

.edit-button:hover {
  text-decoration: underline;
}

a {
  color: #206874;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
